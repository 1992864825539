var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "onpoint-input" }, [
    _c("p", { staticClass: "onpoint-input-label" }, [
      _vm._v("\n    " + _vm._s(_vm.label)),
      _vm.required
        ? _c("i", { staticClass: "onpoint-input-required" }, [_vm._v(" *")])
        : _vm._e(),
    ]),
    !_vm.calendar
      ? _c("div", { staticClass: "onpoint-input-common" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.inputValue,
                expression: "inputValue",
              },
            ],
            staticClass: "onpoint-input-common-insideInput",
            style: _vm.width ? `width:${_vm.width}` : "width:100%",
            attrs: {
              type: "text",
              id: _vm.currency ? "currencyInput" : "input",
              placeholder: _vm.currency ? "R$" : _vm.placeholderValue,
              disabled: _vm.disabled,
            },
            domProps: { value: _vm.inputValue },
            on: {
              blur: function ($event) {
                _vm.touched = true
              },
              change: function ($event) {
                return _vm.$emit("change", _vm.inputValue)
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.inputValue = $event.target.value
              },
            },
          }),
          _vm.showAlert
            ? _c("div", { staticClass: "onpoint-input-common-alertMessage" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.requiredMessage || "Este campo não pode estar vazio"
                    ) +
                    "\n    "
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.calendar
      ? _c(
          "div",
          { staticClass: "onpoint-input-calendar" },
          [
            _c("Calendar", {
              staticClass: "onpoint-input-calendar-insideInput",
              attrs: {
                placeholder: "Selecione uma data",
                disabled: _vm.calendarDisabled,
                minDate: _vm.minDateValue,
                maxDate: _vm.maxDateValue,
                dateFormat: "dd/mm/yy",
              },
              model: {
                value: _vm.calendarDate,
                callback: function ($$v) {
                  _vm.calendarDate = $$v
                },
                expression: "calendarDate",
              },
            }),
            _c("i", {
              staticClass: "onpoint-calendar onpoint-input-calendar-icon",
              style: _vm.calendarDisabled ? "color:grey" : null,
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }