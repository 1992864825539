var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("label", { staticClass: "label" }, [
      _vm._v(_vm._s(_vm.label ? _vm.label : null) + "\n    "),
      _vm.required
        ? _c("i", { staticClass: "required" }, [_vm._v("*")])
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "flex" },
      [
        _c("Dropdown", {
          staticStyle: { width: "100%" },
          attrs: {
            options: _vm.items,
            optionLabel: _vm.optionLabel ? _vm.optionLabel : null,
            placeholder: _vm.optionLabelSelected
              ? _vm.optionLabelSelected.toString()
              : _vm.placeholder
              ? _vm.placeholder.toString()
              : null,
            disabled: _vm.disabled,
            showClear: "",
          },
          on: { input: _vm.sendValue },
        }),
        _vm.optionLabelSelected
          ? _c("button", { staticClass: "inside-select-input" }, [
              _c("i", {
                staticClass: "onpoint-x-circle icon-close-color",
                on: {
                  click: function ($event) {
                    return _vm.deleteValue()
                  },
                },
              }),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }