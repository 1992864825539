<template>
  <div class="container">
    <label class="label"
      >{{ label ? label : null }}
      <i class="required" v-if="required">*</i></label
    >
    <div class="flex">
      <Dropdown
        @input="sendValue"
        :options="items"
        :optionLabel="optionLabel?optionLabel:null"
        :placeholder="
          optionLabelSelected
            ? optionLabelSelected.toString()
            : placeholder
            ? placeholder.toString()
            : null
        "
        :disabled="disabled"
        showClear
        style="width:100%;"
      />
      <button class="inside-select-input" v-if="optionLabelSelected">
        <i class="onpoint-x-circle icon-close-color " @click="deleteValue()" ></i>
      </button>
    </div>
  </div>
</template>

<script>
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
export default {
  props: {
    required: Boolean,
    items: Array,
    label: String || Number,
    placeholder: String || Number,
    optionLabel: String || Number,
    disabled: Boolean,
  },
  components: {
    Dropdown,
    Button,
  },
  data() {
    return {
      optionLabelSelected: null,
    };
  },
  watch: {
    items(value) {
      this.optionLabelSelected = null;
    },
  },
  methods: {
    sendValue(event) {
      this.optionLabelSelected = event[this.optionLabel] || event;
      this.$emit("input", event);
    },
    deleteValue(){
      this.optionLabelSelected  = null
      this.$emit("input", null)
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
}
.label {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: rgba(55, 65, 81, 1);
  margin-bottom: 9px;
}
.required {
  color: rgba(180, 23, 58, 1);
}

.p-dropdown {
  border-radius: 8px;
  border: solid 1px rgba(209, 213, 219, 1);
  background-color: white;
}

:deep(.p-dropdown.p-dropdown-clearable .p-dropdown-label){
  position: relative;
  bottom: 3px !important;
}

.p-disabled {
  border-radius: 8px;
  border: solid 1px rgba(209, 213, 219, 1);
  background-color: #e5e7eb;
}

.inside-select-input{
  position: relative;
  top:10px;
  right: 55px;
  padding: 0px;
  margin: 0px;
  height: 16px;
  width: 16px;
  border: none;
  cursor: pointer;  
}
.icon-close-color{
  color: #7f777c;
}
</style>

