<template>
  <div class="onpoint-input">
    <p class="onpoint-input-label">
      {{ label }}<i class="onpoint-input-required" v-if="required"> *</i>
    </p>
    <div v-if="!calendar" class="onpoint-input-common">
      <input
        type="text"
        class="onpoint-input-common-insideInput"
        :style="width ? `width:${width}` : 'width:100%'"
        :id="currency ? 'currencyInput' : 'input'"
        :placeholder="currency ? 'R$' : placeholderValue"
        :disabled="disabled"
        v-model="inputValue"
        @blur="touched = true"
        @change="$emit('change', inputValue)"
      />
      <div v-if="showAlert" class="onpoint-input-common-alertMessage">
        {{ requiredMessage || "Este campo não pode estar vazio" }}
      </div>
    </div>
    <div v-if="calendar" class="onpoint-input-calendar">
      <Calendar
        placeholder="Selecione uma data"
        class="onpoint-input-calendar-insideInput"
        :disabled="calendarDisabled"
        :minDate="minDateValue"
        :maxDate="maxDateValue"
        v-model="calendarDate"
        dateFormat="dd/mm/yy"
      />
      <i
        class="onpoint-calendar onpoint-input-calendar-icon"
        :style="calendarDisabled ? 'color:grey' : null"
      ></i>
    </div>
  </div>
</template>

<script>
import Calendar from "primevue/calendar";
export default {
  props: {
    label: String,
    required: Boolean,
    disabled: Boolean,
    width: String,
    currency: Boolean,
    calendar: Boolean,
    calendarDisabled: Boolean,
    value: {
      type: [String, Number, Boolean, Date],
      default: "",
    },
    placeholderValue: {
      type: String,
      default: "",
    },
    requiredMessage: String,
    minDateValue: {
      type: Date,
      default: null,
    },
    maxDateValue: {
      type: Date,
      default: null,
    },
  },
  components: { Calendar },
  data() {
    return {
      touched: false,
    };
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    calendarDate: {
      get() {
        return this.value ? new Date(this.value) : null;
      },
      set(newDate) {
        this.$emit("input", newDate ? newDate.toJSON() : null);
      },
    },
    showAlert() {
      return this.required && this.touched && (!this.inputValue || this.inputValue === "");
    },
  },
};
</script>

<style lang="scss" scoped>
.onpoint-input {
  display: flex;
  flex-direction: column;

  :deep(.p-inputtext) {
    font-family: "Montserrat", Helvetica, Arial, sans-serif !important;
    padding: 10.5px;
    border: solid 1px rgba(229, 231, 235, 1);
    border-radius: 12px;
    box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, 0.07);
  }

  &-label {
    font-family: "Montserrat", Helvetica, Arial, sans-serif !important;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: rgba(55, 65, 81, 1);
    margin-bottom: 6px;
  }

  &-required {
    color: rgba(180, 23, 58, 1);
  }

  &-common {
    &-insideInput {
      font-family: "Montserrat", Helvetica, Arial, sans-serif !important;
      font-size: 12px;
      height: 38px;
      padding: 10.5px;
      color: #3f3f46;
      width: 100%;
      border: solid 1px rgba(229, 231, 235, 1);
      border-radius: 12px;
      box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, 0.07);
    }

    &-alertMessage {
      font-family: "Montserrat", Helvetica, Arial, sans-serif !important;
      color: red;
      line-height: 10px;
      font-size: 10px;
      margin-left: 5px;
      margin-top: 5px;
    }
  }

  &-calendar {
    display: block;
    position: relative;

    &-insideInput {
      width: 100%;
    }

    &-icon {
      position: absolute;
      top: 11px;
      right: 14px;
      z-index: 2;
    }
  }
}
</style>
